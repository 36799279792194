<script>
    import { cartStore } from '../../../common/store/cart-store';
    import locale from '../../../i18n/locale';
    import general from '../../../i18n/general';
    import shop from '../../../i18n/shop';

    export default {
        name: 'HeaderCart',

        i18n: locale(general(), shop()),

        data() {
            return {
                storeState: cartStore.state,
            };
        },

        computed: {
            cart() {
                return this.storeState?.cart;
            },

            visible() {
                return this.storeState?.showFastCart;
            },

            cartProductsCount() {
                if (this.cart) {
                    return this.cart.count_products;
                } else {
                    return this.$scope['cart_products_count'] || 0;
                }
            },

            loading() {
                return this.storeState?.fetchingCart;
            },
        },

        watch: {
            cartProductsCount(to, from) {
                // se não estiver na página de carrinho em diante,
                // mostra o popover pro usuário ver que mudou
                if (
                    to > from &&
                    this.cart &&
                    !new RegExp('\/sacola', 'i').test(window.location.href) &&
                    !cr$.queryOne('.cr-modal.opened').exists()
                ) {
                    this.show();
                }
            },
        },

        methods: {
            show() {
                cartStore.show();
                if (!this.loading) {
                    cartStore.fetch().catch((e) => console.error(e));
                }
            },
        },
    };
</script>
