<template>
    <div class="d-flex">
        <div class="header-account" v-if="user">
            <button class="expandable-compact btn btn-image btn-light btn-header btn-height mr-2">
                <i class="header-account-icon icon-cr icon-user"></i>
            </button>
            <div class="expandable-content" id="header-topbar-dropdown">
                <div class="div-sidebar bg-black text-center px-4">
                    <p class="mt-3 text-white">
                        Olá {{ user.FIRST_NAME }}, você fez
                        <br />
                        login pela sua conta
                    </p>
                    <div class="logo-dropdown-up logo-up-text my-2 mx-2"></div>
                </div>
                <div class="div-sidebar sidebar bg-white mx-0 text-center">
                    <div class="my-2">
                        <a :href="`${$scope.URL_STORE_PREFIX}/minha-conta/meus-dados`"
                            >Meus Dados</a
                        >
                        <hr class="mx-3 my-2" />
                        <a :href="`${$scope.URL_STORE_PREFIX}/minha-conta/pedidos`">Ver Pedidos</a>

                        <hr class="mx-3 my-2" />
                        <a :href="`${$scope.URL_STORE_PREFIX}/minha-conta/wishlist`"
                            >Ver Wishlist</a
                        >

                        <hr class="mx-3 my-2" v-if="user.STORE_ID" />
                        <a v-if="user.STORE_ID" :href="`${$scope.URL}painel/home`">Minha Loja</a>

                        <hr class="mx-3 my-2" />
                        <a :href="`${$scope.URL_STORE_PREFIX}/logout`" class="logout">Sair</a>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <a
                :href="`${$scope.URL_STORE_PREFIX}/entrar`"
                class="btn btn-image btn-light btn-header btn-height mr-2"
            >
                <i class="header-account-icon icon-cr icon-user"></i>
            </a>
        </div>
        <header-cart-desktop />
    </div>
</template>

<script>
    import { PhCaretDown } from 'phosphor-vue';
    import HeaderCartDesktop from '../header-cart-desktop/HeaderCartDesktop';

    export default {
        name: 'HeaderAccount',

        components: { HeaderCartDesktop, PhCaretDown },

        props: {
            user: {
                type: Object,
            },
            cartProductsCount: Number,
        },
    };
</script>
