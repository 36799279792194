<template>
    <nav>
        <HeaderDesktop :links="links" :customer="customer" />
        <HeaderMobile :links="links" :customer="customer" />
        <HeaderCartDrawer :customer="customer" />
    </nav>
</template>

<script>
    import HeaderDesktop from '../header-desktop/HeaderDesktop';
    import HeaderMobile from '../header-mobile/HeaderMobile';
    import HeaderCartDrawer from '../header-cart-drawer/HeaderCartDrawer';

    export default {
        name: 'Header',
        components: {
            HeaderDesktop,
            HeaderMobile,
            HeaderCartDrawer,
        },
        props: {
            links: {
                type: Array,
                required: true,
            },
            customer: Object,
            store: Boolean,
        },
    };
</script>
