<template>
    <div id="header-desktop" class="hidden-sm-down header pos-relative bg-header-2">
        <div
            id="header-desktop-topbar-wrapper"
            class="header-topbar-wrapper pos-fixed pos-top pos-left pos-right"
        >
            <div
                id="header-topbar"
                class="d-flex content"
                :class="{ 'header-scroll': scrollHeader }"
            >
                <div id="header-desktop-logo-wrapper">
                    <div
                        class="h-full d-flex flex-align-center flex-spaced-around"
                        :class="{ 'mr-4': !$scope.STORE || !$scope.STORE.logo_url }"
                    >
                        <a
                            v-if="$scope.STORE && $scope.STORE.logo_url"
                            :href="`/${$scope.STORE.url}/`"
                            :title="$scope.STORE.name"
                        >
                            <img
                                :class="!scrollHeader ? 'img-logo-store' : 'img-logo-scroll-store'"
                                class="lazy center-block"
                                :data-src="`${$scope.CDN_IMAGES}/${$scope.STORE.logo_url}?auto=compress,format&q=65&w=320&h=68`"
                                :alt="$scope.STORE.name"
                            />
                        </a>
                        <a
                            v-else
                            href="/"
                            class="logo-up-full-h"
                            :class="!scrollHeader ? 'header-logo-up' : 'header-logo-scroll-up'"
                            title="Mais Uni"
                        ></a>
                    </div>
                </div>
                <HeaderLinks
                    :links="links"
                    :scroll-header="scrollHeader"
                    class="header-text-color mr-auto"
                />
                <HeaderAccount :user="customer" />
            </div>
        </div>
    </div>
</template>

<script>
    import HeaderLinks from '../header-links/HeaderLinks';
    import HeaderAccount from '../header-account/HeaderAccount';

    export default {
        name: 'HeaderDesktop',
        components: {
            HeaderLinks,
            HeaderAccount,
        },
        props: {
            links: {
                type: Array,
                required: true,
            },
            customer: Object,
        },
        data() {
            return {
                scrollHeader: false,
            };
        },
        mounted() {
            window.addEventListener(
                'scroll',
                () => {
                    if (window.scrollY > 0 && !this.scrollHeader) {
                        this.scrollHeader = true;
                    } else if (window.scrollY === 0 && this.scrollHeader) {
                        this.scrollHeader = false;
                    }
                },
                { passive: true }
            );
        },
    };
</script>