<template>
    <div :class="{ opened: isVisible }" class="collapsible">
        <div
            :class="[{ 'alert-danger': isError, 'alert-success': !isError }, alertClass]"
            class="mb"
        >
            {{ message }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Alert',

        props: ['alert', 'alertClass'],

        data() {
            return {
                isVisible: false,
                timeoutId: null,
                message: null,
                isError: false,
                timeout: 4000,
            };
        },

        watch: {
            alert: {
                immediate: true,
                handler(newAlert) {
                    if (newAlert && newAlert.message != null) {
                        if (this.timeoutId) clearTimeout(this.timeoutId);

                        const update = () => {
                            this.isError = newAlert.isError;
                            this.message = newAlert.message;
                            this.isVisible = true;
                            if (newAlert.timeout === 0) {
                                // se timeout é 0, desativamos e o alerta não some
                                this.timeout = null;
                                this.timeoutId = null;
                            } else {
                                this.timeout = this.timeout || 4000;
                                this.timeoutId = setTimeout(
                                    () => (this.isVisible = false),
                                    this.timeout
                                );
                            }
                            newAlert = null;
                        };

                        if (this.isVisible) {
                            this.isVisible = false;
                            setTimeout(update, 250);
                        } else update();
                    } else {
                        this.isVisible = false;
                    }
                },
            },
        },

        methods: {
            updateAlert(message, isError, timeout = undefined) {
                // Ajusta tempo para fechar alerta sozinho. Informe 0 ou null para manter a exibição
                this.timeout = typeof timeout !== 'undefined' ? timeout : this.timeout;

                if (message != null) {
                    if (this.timeoutId) clearTimeout(this.timeoutId);

                    const update = () => {
                        this.isError = isError;
                        this.message = message;
                        this.isVisible = true;
                        if (this.timeout > 0) {
                            this.timeoutId = setTimeout(
                                () => (this.isVisible = false),
                                this.timeout
                            );
                        }
                    };

                    if (this.isVisible) {
                        this.isVisible = false;
                        setTimeout(update, 250);
                    } else update();
                }
            },
        },
    };
</script>