export default () => ({
    pt: {
        _checkout: {
            backToPurchase: 'Voltar às compras',
            _discount: {
                haveDiscountClickHere: 'Possui cupom de desconto? Clique aqui',
                haveDiscountOrGiftCode: 'Possui um cupom de desconto ou vale-presente?',
                clickToAddGivenDiscount:
                    'Clique aqui para aproveitar o cupom de {0} que você ganhou!',
                appliedDiscounts: 'Descontos já aplicados',
                errorRemove: 'Não foi possível remover o cupom.',
                errorAdd: 'Não foi possível adicionar o cupom.',
            },
            _address: {
                errorSavingAddress:
                    'Não foi possível salvar sua escolha do endereço. Por favor, tente novamente.',
                errorFetchingAddresses:
                    'Não foi possível encontrar seus endereços. Por favor, tente novamente.',
            },
            _shipping: {
                selectCarrierBelow: 'Selecione uma opção de entrega abaixo',
                motoBoyObservations: [
                    'As entregas são feitas das 8h às 12h e das 14h às 17h;',
                    'Caso não haja ninguém no local para receber o pedido, será cobrada nova taxa de entrega.',
                ],
                shippingForecast: 'Previsão de envio',
                enterShippingToProceed: 'Informe uma forma de entrega para prosseguir',
                errorSavingCarrier:
                    'Não foi possível salvar sua escolha da entrega. Por favor, tente novamente.',
            },
            reviewOrder: 'Revise seu pedido',
            checkEverything: 'Confira se realmente está tudo certo',
            paymentBy: 'Pagamento por',
            finishingOrder: 'Estamos finalizando seu pedido',
            almostThere: 'Calma, que tá quase',
            updateAccount: 'Atualizar cadastro',
            updateAccountWithDocForBrazilX:
                'Para entregas no Brasil, você precisa atualizar seu cadastro com seu {0}. Após incluir o documento no seu cadastro você poderá finalizar o seu pedido.',
            errorLoadItems: 'Não conseguimos carregar os itens do seu pedido',
            errorProcessing:
                'Ocorreu uma falha no processamento do seu pedido. Por favor, atualize a página e tente novamente. Se o erro persistir, entre em contato conosco através dos nossos canais de atendimento.',
            _payment: {
                selectMethod: 'Selecione uma forma de pagamento',
                selectMethodBelow: 'Selecione um método de pagamento abaixo',
                errorLoadMethodsTryAgain:
                    'Erro ao carregar métodos de pagamento. Recarregue a página.',
                _card: {
                    name: 'Nome do Titular',
                    number: 'Número do cartão',
                    expiration: 'Vencimento',
                    selectInstallments: 'Selecione o parcelamento',
                    interestsMonth: 'c/ juros de {0}% a.m.',
                },
                errorWalletCancelledX: 'O pagamento pelo {0} não foi concluído.',
                errorWalletFailedX:
                    'Não foi possível realizar o pagamento através do {0}. Tente outro método.',
                walletCardSuccessfullySelectedX: 'Seu cartão foi selecionado com sucesso no {0}.',
                paypalInstruction:
                    'Ao finalizar o pedido você será redirecionado para o PayPal para prosseguir com o pagamento.',
                depositInstruction:
                    'Selecione o banco que deseja realizar o depósito para confirmar o seu pedido:',
                errorWalletConnectionTryAgain:
                    'Ocorreu uma falha durante a comunicação com o {0}. Por favor, atualize a página e tente novamente.',
                walletInstructionsHtmlX: `
                    <p>
                        Utilize seus cartões armazenados no {0} e compre com mais
                        rapidez.
                    </p>
                    <p>
                        Quando estiver pronto para efetuar seu pedido, tudo que você
                        precisa fazer é:
                    </p>
        
                    <ol>
                        <li>Clicar no botão “{0}”</li>
                        <li>Identificar-se e selecionar o cartão de crédito</li>
                        <li>Confirmar seu pedido no {0}</li>
                        <li>
                            Ao retornar para a página de pagamento do Mais Uni,
                            selecionar a opção de parcelamento
                        </li>
                        <li>Finalizar seu pedido</li>
                    </ol>`,
            },
            invalidDiscountRecalculate:
                'O cupom utilizado não é válido e foi necessário recalcular o valor do seu pedido.',
        },
    },
    en: {
        _checkout: {
            backToPurchase: 'Back to bag',
            _discount: {
                haveDiscountClickHere: 'Have a discount discount? Click here',
                haveDiscountOrGiftCode: 'Have a discount discount or gift code?',
                clickToAddGivenDiscount:
                    'Click here to take advantage of the {0} discount you won!',
                appliedDiscounts: 'Discounts already applied',
                errorRemove: 'The discount could not be removed.',
                errorAdd: 'The discount could not be added.',
            },
            _address: {
                errorSavingAddress: 'Failed to change address. Please try again.',
                errorFetchingAddresses: 'Failed to find your addresses. Please try again.',
            },
            _shipping: {
                selectCarrierBelow: 'Select a shipping option below',
                motoBoyObservations: [
                    'Shipping is made from 8 am to 12 pm and from 2 pm to 5 pm;',
                    'If there is no one on site to receive the order, a new shipping fee will be charged.',
                ],
                shippingForecast: 'Shipping forecast',
                enterShippingToProceed: 'Enter a shipping method to proceed',
                errorSavingCarrier: 'We were unable to save the shipping option. Please try again.',
            },
            reviewOrder: 'Review your order',
            checkEverything: 'Check if everything is correct',
            paymentBy: 'Payment method',
            finishingOrder: 'We are finishing your order',
            almostThere: 'Almost there',
            updateAccount: 'Update your account',
            updateAccountWithDocForBrazilX:
                "To ship to Brazil, you need to update your account with your {0}. After including the document in your account you'll be able to complete your order.",
            errorLoadItems: 'There was an error while loading your order',
            errorProcessing:
                'There was an error while finishing your order. Please refresh the page and try again. If the error persists, contact us through our support channels.',
            _payment: {
                selectMethod: 'Select a payment method',
                selectMethodBelow: 'Select a payment method below',
                errorLoadMethodsTryAgain:
                    'Failed to load the payment methods. Refresh the page and try again.',
                _card: {
                    name: 'Name on card',
                    number: 'Card number',
                    expiration: 'Expiration',
                    selectInstallments: 'Select installments',
                    interestsMonth: 'interests at {0}%/month',
                },
                errorWalletCancelledX: 'The payment through {0} has not been completed.',
                errorWalletFailedX:
                    'It was not possible to make the payment through {0}. Try another method.',
                walletCardSuccessfullySelectedX:
                    'Your card has been successfully selected on the {0}.',
                paypalInstruction:
                    'After finishing your order you will be redirected to PayPal to proceed with the payment.',
                depositInstruction:
                    'Select the bank you want to make the deposit to confirm your order',
                errorWalletConnectionTryAgain:
                    'Failed to communicate with {0}. Please refresh the page and try again.',
                walletInstructionsHtmlX: `
                    <p>
                        Use your cards saved at {0} and buy faster.
                    </p>
                    <p>
                        When you are ready to place your order, follow the following steps:
                    </p>
        
                    <ol>
                        <li>Click on the “{0}” button</li>
                        <li>Identify yourself and select your credit card</li>
                        <li>Confirm your order on {0}</li>
                        <li>
                            When returning to the Mais Uni payment page, select the installment option
                        </li>
                        <li>Finalize your order</li>
                    </ol>`,
            },
            invalidDiscountRecalculate:
                'The discount used is invalid and it was necessary to recalculate the value of your order.',
        },
    },
});
