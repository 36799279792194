import {
    addressService,
    categoryService,
    countryService,
    customerService,
    exchangeService,
    fabricService,
    unityService,
    getCustomerAddressService,
    getCustomerOrderService,
    hashtagService,
    productService,
} from '../service/resource';

const services = {
    category: categoryService,
    customer: customerService,
    address: addressService,
    exchange: exchangeService,
    fabric: fabricService,
    product: productService,
    getCustomerOrder: getCustomerOrderService,
    getCustomerAddress: getCustomerAddressService,
    country: countryService,
    hashtag: hashtagService,
    unity: unityService,
};

const Services = {
    install: (Vue) => {
        Vue.prototype.$services = services;
    },
};

export default Services;
