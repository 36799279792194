<template>
    <Header :links="links" :customer="customer" :store="store" />
</template>

<script>
    import Header from './Header';
    import { catalogStore } from '../../../../common/store/catalog-store';

    export default {
        name: 'HeaderContainer',

        components: { Header },

        data() {
            return {
                storeState: catalogStore.state,
                store: !!this.$scope.STORE,
                customer: this.$scope.CUSTOMER,
                catalogCategories: null,
            };
        },

        computed: {
            filters() {
                return this.storeState.filters;
            },

            links() {
                const menu = this.$scope.STORE
                    ? [
                          // {
                          //     id: '1',
                          //     label: 'Loja',
                          //     href: '/' + this.$scope.STORE.url + '/',
                          // },
                          // {
                          //   id: '2',
                          //   label: 'Contato',
                          //   href: '/' + this.$scope.STORE.url + '/fale-conosco',
                          // },
                          // {
                          //     id: '2',
                          //     label: 'Sobre',
                          //     href: '/' + this.$scope.STORE.url + '/',
                          // },
                      ]
                    : this.$scope.HEADER_ITEMS;

                if (this.$scope.STORE && this.filters && this.filters.categories) {
                    const filterOrder = [1306, 1305, 1307];

                    this.filters.categories
                        .sort((a, b) =>
                            filterOrder.indexOf(a.id) > filterOrder.indexOf(b.id) ? 1 : -1
                        )
                        .forEach((category) => {
                            menu.push({
                                id: category.id,
                                label: category.name,
                                href: '/' + this.$scope.STORE.url + '/loja/' + category.url + '/',
                            });
                        });
                }

                return menu;
            },
        },
    };
</script>