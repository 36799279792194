import accounting from './accounting';
import { timeHandler } from '../common/common';

export function formatPhone(value) {
    if (value !== undefined && value !== null) {
        if (value.length === 11) {
            let matches = value.match(/(\d{2})(\d{5})(\d{4})/);
            if (matches) {
                return `(${matches[1]}) ${matches[2]}-${matches[3]}`;
            }
        }

        if (value.length === 10) {
            let matches = value.match(/(\d{2})(\d{4})(\d{4})/);
            if (matches) {
                return `(${matches[1]}) ${matches[2]}-${matches[3]}`;
            }
        }

        return value;
    }
}

export function formatCnpj(value) {
    if (value !== undefined && value !== null) {
        if (value.length === 14) {
            let matches = value.match(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/);
            if (matches) {
                return `${matches[1]}.${matches[2]}.${matches[3]}/${matches[4]}-${matches[5]}`;
            }
        }
        return value;
    }
}

export function formatDate(value, format = 'DD/MM/YYYY') {
    if (value) {
        return timeHandler(String(value)).format(format);
    }
}

export function formatMoney(value) {
    if (value !== undefined && value !== null) {
        return accounting.formatMoney(value, 'R$ ', 2, '.', ',');
    }
}

export function number(value, fix = 0) {
    return Number.parseFloat(value).toFixed(fix);
}

export function removeInlineStyles(value) {
    return value.replace(/style=".*?"/g, '');
}
