<template>
    <div>
        <ul v-if="products" ref="list" :class="innerListClass" @scroll="$emit('scroll', $event)">
            <ProductListItem
                v-for="product in limitedProducts"
                :small="small"
                :key="`${product.id}-${gender}`"
                :gender="gender"
                :options="innerItemOptions"
                :product="product"
                :selected-filters="selectedFilters"
                :shorter-image="shorterImage"
                @click="pushItemEvent(product)"
            >
                <template v-slot:below-image>
                    <slot name="below-image" :product="product" />
                </template>
            </ProductListItem>
        </ul>
    </div>
</template>

<script>
    import ProductListItem from '../product/ProductListItem';
    import Slider from '../../common/hammer-slider';
    import { lazyLoadInstance } from '../../common/common';

    const DEFAULT_ITEM_OPTIONS = {
        baseUrl: window.url,
        displayName: true,
        displayArt: true,
        displayPrice: true,
        displayColors: false,
        imageWidth: 290,
        imageHeight: 435,
        lang: 'pt',
    };

    export default {
        name: 'ProductList',

        components: { ProductListItem },

        props: {
            products: {
                type: Array,
                required: true,
            },
            itemOptions: {
                type: Object,
                default: () => DEFAULT_ITEM_OPTIONS,
            },
            sliderOptions: {
                type: Object,
            },
            selectedFilters: {
                type: Object,
            },
            eventName: String,
            currentPage: {
                type: Number,
                default: 0,
            },
            perPage: {
                type: Number,
                default: 0,
            },
            gender: {
                type: Number,
                default: 0,
            },
            listClass: {
                type: String | Object,
                default: 'product-list product-list-4 product-list-gap-y-4',
            },
            shorterImage: Boolean,
            small: Boolean,
        },

        data() {
            return {
                productsLayer: null,
            };
        },

        watch: {
            products: {
                immediate: true,
                handler(to) {
                    if (!to) return;
                    this.pushListEvent();
                    this.$nextTick(() => {
                        if (this.sliderOptions) {
                            new Slider(this.$refs.list).init(this.sliderOptions, () => {
                                lazyLoadInstance.update();

                                // register native click handlers because the slider changes the elements
                                const itemsEls = cr$.byEl(this.$refs.list).children('li');
                                for (let i = 0; i < itemsEls.length(); i++) {
                                    cr$.byEl(itemsEls.raw[i]).click(() =>
                                        this.pushItemEvent(this.products[i])
                                    );
                                }
                            });
                        } else {
                            lazyLoadInstance.update();
                        }
                    });
                },
            },
        },

        computed: {
            limitedProducts() {
                return (this.options || {}).limit > 0
                    ? this.products.slice(
                          this.options.offset,
                          this.options.offset + this.options.limit
                      )
                    : this.products;
            },

            innerListClass() {
                let classes = { slider: this.sliderOptions };

                if (typeof this.listClass === 'string') {
                    classes[this.listClass] = true;
                } else {
                    classes = { ...classes, ...this.listClass };
                }

                return classes;
            },

            innerItemOptions() {
                const inner = { ...this.itemOptions };

                for (let prop in DEFAULT_ITEM_OPTIONS) {
                    if (DEFAULT_ITEM_OPTIONS.hasOwnProperty(prop) && inner[prop] === undefined) {
                        inner[prop] = DEFAULT_ITEM_OPTIONS[prop];
                    }
                }

                return inner;
            },
        },

        methods: {
            pushListEvent() {
                if (!this.eventName) return;

                window.dataLayer = window.dataLayer || [];

                this.productsLayer = this.products.map((product, index) => {
                    return {
                        item_name: product.name, // Name or ID is required.
                        item_id: product.id + '',
                        price: product.price,
                        item_brand: (product.store || {}).name || 'Mais Uni',
                        item_category: product.type.name,
                        item_list_name: this.eventName,
                        index: this.perPage * (this.currentPage - 1) + index,
                        quantity: 1,
                    };
                });
                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                    event: 'view_item_list',
                    ecommerce: {
                        items: this.productsLayer,
                    },
                });
            },

            pushItemEvent(product) {
                if (!this.eventName) return;
                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                    event: 'select_item',
                    ecommerce: {
                        items: [this.productsLayer.find((item) => item.item_id == product.id + '')],
                    },
                });
            },
        },
    };
</script>